<template>
  <section :class="['app-layout', { showHeader: showHeader, showSide: showSide }]">
    <header v-if="showHeader">
      <ViewHeader />
    </header>
    <main>
      <template v-if="showSide">
        <section class="aside">
          <ViewAside />
        </section>
      </template>
      <section class="main">
        <ViewMain />
      </section>
    </main>
  </section>
  <div v-show="route.path === '/home'">
    <Mail />
    <!-- <Notice /> -->
    <viewZj />
  </div>
  <LoginDialog />
</template>
<script lang="ts" setup>
import useCheckedMenu from '@/hooks/state/use-checked-menu'
import ViewHeader from './components/header.vue'
import ViewMain from './components/main.vue'
import ViewAside from './components/aside.vue'
import LoginDialog from '../Login/view.vue'
import Mail from './components/extra/mail.vue'
import Notice from './components/extra/notice.vue'
import viewZj from './components/extra/zj.vue'
import { useRoute } from 'vue-router'
const { showHeader, showSide } = useCheckedMenu()
const route = useRoute()
</script>
<style lang="scss" scoped>
.app-layout {
  position: relative;
  width: 100%;
  &.showHeader {
    header {
      height: $page-header-height;
      padding: 0;
    }
  }
  header {
    position: relative;
    width: 100%;
    z-index: 1;
  }

  main {
    position: relative;
    display: flex;
    .aside {
      width: $page-aside-width;
    }
    .main {
      flex: 1;
    }
  }
  .fixed-container {
    position: fixed;
  }
  .bg-box {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
