<template>
  <div class="mail-icon">
    <!-- <img
      src="/static/images/home/mail/mail-icon.png"
      @click="show = true"
    > -->
    <!-- <img
      src="/static/images/home/item-icon-3.png"
      @click="openDetail"
    > -->
    <img
      src="/static/images/home/icon-solicit.png"
      @click="openDetail"
    >
  </div>
  <!-- <el-dialog
    v-model="show"
    append-to-body
    class="letter-dialog"
    width="880px"
    :show-close="false"
    @close="dialogClose"
  >
    <img
      v-show="!showContent"
      class="mail-cover"
      src="/static/images/home/mail/mail-cover.png"
      @click="showContent = true"
    >
    <img
      v-show="showContent"
      class="mail-content"
      src="/static/images/home/mail/mail.png"
    >
  </el-dialog> -->
</template>
<script setup lang="ts">
import { ref } from 'vue'

const show = ref(false)
const showContent = ref(false)
function dialogClose() {
  showContent.value = false
  show.value = false
}

const apiPrefix = import.meta.env.VITE_ENV === 'prod' ? '/yuhangplatform' : ''

function openDetail() {
  // window.open(
  //   apiPrefix + '/web-company-pc-v2/index.html#/zwyw/qqnews-detail?id=358'
  // )
  // window.open('https://www.wjx.cn/vm/evsYlsf.aspx')
  window.open('https://mp.weixin.qq.com/s/PAgGODfFYpBIR7AorqsTqw')
}
</script>
<style lang="scss" scoped>
@keyframes scale {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}
.mail-icon {
  position: fixed;
  right: 0;
  bottom: calc(20% + 240px);
  z-index: 999;

  img {
    cursor: pointer;
    // width: 148px;
    // height: 148px;
    width: 154px;
    height: 69px;
    animation: scale 2s infinite;
  }
}
@keyframes slideUp {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
.mail-cover {
  width: 880px;
  height: 605px;
  cursor: pointer;
  animation: slideUp 2s ease forwards;
}
@keyframes rotateIn {
  0% {
    transform: perspective(880px) rotateX(90deg);
    opacity: 0;
  }
  100% {
    transform: perspective(880px) rotateX(0deg);
    opacity: 1;
  }
}
.mail-content {
  width: 880px;
  animation: rotateIn 2s ease both;
}
</style>
<style lang="scss">
.letter-dialog {
  background: transparent !important;
  box-shadow: unset !important;
  .el-dialog__header {
    padding: 0;
  }
  .el-dialog__body {
    padding: 0;
  }
}
</style>
